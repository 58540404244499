<style scoped lang="scss">
  .BODY{
    background: none;
  }
  .newShoppingCarList {
    /*width: 100%;*/
    margin: .30rem .40rem .20rem;
    background: #FFFFFF;
    box-shadow: 0 .02rem .20rem 0 rgba(162, 170, 221, 0.29);
    border-radius: .08rem;
    display: block;

    .head {
      margin: 0 .23rem;
      padding: .30rem 0;
      border-bottom: .01rem solid #f1f1f1;
      display: flex;
      align-items: center;

      .img {
        width: .52rem;
        height: .52rem;
        margin-right: .16rem;
      }

      .name {
        color: #313131;
        font-size: .30rem;
      }
    }

    .cont {
      padding: .30rem 0;
      display: flex;

      & + .cont {
        padding-top: 0;
      }

      label {
        width: .98rem;
        /*height: 100%;*/
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .img {
        width: 1.80rem;
        height: 1.80rem;
        position: relative;
        border-radius: .12rem;
        -moz-border-radius-topleft: 0;
        border-radius-topleft: 0;
        overflow: hidden;
        margin-right: .20rem;

        .skuImg {
          width: 100%;
          height: 100%;
        }

        .region {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 2;
          width: .68rem;
          height: .68rem;
        }
      }

      .info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: .30rem;

        .name {
          width: 100%;
          height: .90rem;
          line-height: .45rem;
          font-size: .32rem;
          font-weight: 400;
          color: #222222;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-all;
        }

        .operation {
          display: flex;
          justify-content: space-between;

          .num {
            display: flex;
            align-items: center;

            img {
              width: .48rem;
              height: .48rem;
            }

            span {
              width: .62rem;
              text-align: center;
              height: 0.48rem;
              line-height: 0.48rem;
            }

            /*display: none;*/
          }

          .price {
            color: #222;
            font-size: .32rem;
          }
        }
      }
    }
  }

  .BODY {
    padding: .84rem 0 1.34rem;
  }

  .header {
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    text-align: center;
    color: #222;
    height: .84rem;
    line-height: .84rem;
    font-size: .36rem;
    background: #fff;

    .operation {
      height: .84rem;
      line-height: .84rem;
      padding: 0 .40rem;
      position: absolute;
      top: 0;
      right: 0;
      font-size: .30rem;
      color: #E71F19;

      &.operation2 {
        font-size: .28rem;
        color: #999;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: .32rem;
          height: .32rem;
          margin-right: .08rem;
        }
      }
    }

    .tit {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: .32rem;
        height: .32rem;
        margin-right: .10rem;
      }

      span {
        font-size: .36rem;
        color: #222;
      }
    }

  }

  .foot {
    width: 100%;
    height: 1.34rem;
    background: #FFFFFF;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    position: fixed;
    z-index: 4;
    left: 0;
    bottom: 0;

    & > div {
      display: flex;
      height: 100%;
      padding-left: .50rem;


      .box {
        padding-right: .30rem;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .30rem;
        color: #222;
        line-height: .40rem;

        input[checkbox] {
          margin-right: .10rem;
          transform: translateY(-0.3rem);
        }

        .desc {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .price {
            font-size: .36rem;
            color: #222;
            font-weight: bold;
          }

          .num {
            margin-top: .08rem;
            font-size: .22rem;
            color: #999;
          }
        }
      }

      .btn {
        width: 2.28rem;
        line-height: 1.34rem;
        text-align: center;
        color: #222;
        font-size: .36rem;
        font-weight: bold;
        border-radius: 0;
        /*background-img: linear-gradient(-90deg, #F6C24A 0%, #FFBD44 100%);*/
        background: #F6C24A;
      }
    }

  }

  .none {
    text-align: center;

    & > img {
      width: 1.80rem;
      height: 1.80rem;
      margin: 2.17rem auto .30rem;
    }

    & > div {
      & > span {
        font-size: .32rem;
        color: #999;
      }
    }
  }

  /*默认样式*/
  input[type="checkbox"] {
    width: .40rem;
    height: .40rem;
    box-sizing: border-box;
    border-radius: 50%;
    background: #FFFFFF;
    border: .04rem solid #E0E0E0;
    vertical-align: middle;
    -webkit-appearance:none;
    /*background: url("../assets/imgs/unCheck.png") no-repeat center;*/
    /*background-size: .40rem .40rem;*/
  }

  /* 选中后的样式 */
  input[type="checkbox"]:checked {
    border: none;
    background: #F6C24A;
    background: url("../assets/imgs/check.png") no-repeat center;
    background-size: .40rem .40rem;
  }

  /* 选中后的对勾样式 */
  input[type="checkbox"]:checked:before {
    font-size: .24rem; /* 对勾大小 .30rem */
    color: #222; /* 对勾颜色 白色 */
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }

  /*禁用后的样式*/
  .disabled {
    input[type="checkbox"]{
      background: #F8F8F8;
      border: .04rem solid #E0E0E0;
    }
  }

  .cancelOrderModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);

    .modalBox {
      width: 6.10rem;
      /*height: 4.33rem;*/
      border-radius: .08rem;
      padding: .30rem .40rem 0;
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .tit {
        font-size: .48rem;
        color: #181944;
        height: .67rem;
        font-weight: bold;
      }

      .desc {
        padding: .30rem .10rem .40rem;
        font-size: .36rem;
        color: #222;
        font-weight: bold;
        border-bottom: .01rem solid #E0E0E0;

      }

      .btnRow {
        height: 1.05rem;
        overflow: hidden;

        & > .btn {
          width: 50%;
          float: left;
          font-size: .32rem;
          text-align: center;
          padding: .30rem 0;
        }

        .confirm {
          color: #F6C24A;
          transform: translateX(.20rem);
          background: transparent;
          line-height: normal;
        }

        .cancel {
          color: #555;
          transform: translateX(-.20rem);
        }
      }

      .line {
        position: absolute;
        z-index: 2;
        left: 50%;
        bottom: .22rem;
        width: .01rem;
        height: .60rem;
        background: #E0E0E0;
      }
    }
  }
</style>

<template>
  <div class="BODY">
    <!--正常状态-->
    <div v-if="!isEdit && totalSkuNumber !== 0">
      <!--头部-->
      <div class="header">
        <div class="tit">
          <img src="../assets/imgs/shoppingCar-s.png"/>
          <span>购物车</span>
        </div>
        <!--<span class="operation" @click="edit">编辑</span>-->
        <div class="operation operation2" @click="clearCar">
          <img src="../assets/imgs/clear.png"/>
          <span>清空</span>
        </div>
      </div>

      <!--商品列表-->
      <form>
        <div class="newShoppingCarList" v-for="(item,index) in supplierList">
          <div class="head">
            <img class="img" :src="supplierList2[index]" v-if="supplierList2[index]"/>
            <span class="name">{{item}}</span>
          </div>
          <div class="cont"
               v-for="(data,i) in shoppingCarList"
               v-if="data.supplierName === item"
          >
            <label>
              <div class="checkbox">
                <input type="checkbox" @change="checkboxChange(data.check, i)" v-model="data.check" :value="i" :disabled="data.skuCount === 0"/>
              </div>
            </label>
            <div class="img">
              <img class="skuImg" :src="data.skuImage"/>
              <img class="region" src="../assets/imgs/a.png" v-if="data.region === 'A'"/>
              <img class="region" src="../assets/imgs/b.png" v-if="data.region === 'B'"/>
              <img class="region" src="../assets/imgs/c.png" v-if="data.region === 'C'"/>
              <img class="region" src="../assets/imgs/d.png" v-if="data.region === 'D'"/>
              <img class="region" src="../assets/imgs/e.png" v-if="data.region === 'E'"/>
              <img class="region" src="../assets/imgs/f.png" v-if="data.region === 'F'"/>
            </div>
            <div class="info">
              <div class="name">{{data.skuName}}</div>
              <div class="operation">
                <div class="num">
                  <img src="../assets/imgs/reduce.png" @click="reduce(data)"/>
                  <span>{{data.number}}</span>
                  <img src="../assets/imgs/increase.png" @click="increase(data)"
                       v-if="data.skuCount > data.number && data.number < maxNum"/>
                  <img src="../assets/imgs/increase-d.png"
                       v-if="data.skuCount <= data.number || data.number >= maxNum"/>
                </div>
                <div class="price">
                  <span style="font-size: .22rem;">￥</span>{{data.skuPrice}}
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>

      <!--底部-->
      <div class="foot">
        <div>
          <div class="box">
            <label class="checkbox">
              <input type="checkbox" @change="allChoice(allChoice_)" v-model="allChoice_"/>
              全选
            </label>

            <div class="desc">
              <span class="price">合计:
                <span style="font-size: .28rem;">￥</span>{{totalPrice}}
              </span>
              <span class="num">已选
                <span style="color: #E71F19;">{{skuNum}}</span>
                件商品
              </span>
            </div>
          </div>

          <button class="btn" @click="createOrder">去支付</button>
        </div>
      </div>
    </div>

    <!--确认清空的弹窗-->
    <div class="cancelOrderModal" v-if="showClearModal">
      <div class="modalBox">
        <div class="tit">提示</div>
        <div class="desc">确认清空购物车吗？</div>
        <div class="btnRow">
          <div class="btn cancel" @click="cancel2()">取消</div>
          <div class="btn confirm" @click="confirm2()">确定</div>
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
    import request from "@/utils/request"

    export default {
        name: 'ShoppingCar',
        components: {},
        props: {},
        data() {
            return {
                isEdit: false,

                skuNum:0,//选中商品数量
                totalPrice:0,
                totalSkuNumber:0,//购物车中所有商品数量
                shoppingCarList:[],
                supplierList:[],//商家名称列表
                supplierList2:[],//商家图片列表

                allChoice_:true,
                disabled:true,//true置灰不能删除 false可以删除
                showDelModal:false,//删除商品
                showClearModal:false,//清空购物车
                maxNum:5,
                userInfo:{
                  grayRelease:true,
                },
            }
        },
        computed: {},
        mounted: function () {
            console.log(this.$route, 'this.$route')
            this.shoppingCarList = JSON.parse(sessionStorage.getItem('shoppingCar')) || [];
            this.totalSkuNumber = JSON.parse(sessionStorage.getItem('shoppingCarNum')) || 0;
            this.upDataSupplierList();
            this.upDateData();
        },
        methods: {
            //更新价格和数量
            upDateData(){
                let totalPrice = 0;
                let skuNum = 0;
                let totalSkuNumber = 0;
                this.shoppingCarList.forEach(v=>{
                    totalSkuNumber = totalSkuNumber + v.number;
                    if (v.check) {
                        // totalPrice = (totalPrice*100 + (v.skuPrice * v.number)*100)/100;
                        totalPrice = (this.getTotalPrice(totalPrice) + (this.getTotalPrice(v.skuPrice) * v.number))/100;
                        skuNum = skuNum + v.number;
                    }
                });
                this.totalSkuNumber = totalSkuNumber;
                this.totalPrice = totalPrice;
                this.skuNum = skuNum;
                if (this.totalSkuNumber === 0) {
                    this.$router.go(-1);
                }
            },
            cancelOrder() {
                Request(API.cancelOrder, {
                    method: 'POST',
                    loading:1,
                    data: {
                        orderCode:this.orderCode
                    }
                }, res => {
                    console.log(`取消订单${this.orderCode}成功`);
                });
            },
            //清空购物车
            clearCar(){
                this.showClearModal = true;
            },
            //加减商品
            reduce(item){//减少
                console.log(item);
                this.shoppingCarList = this.shoppingCarList.filter(v=>{
                    if (v.skuCode === item.skuCode && v.skuPrice === item.skuPrice) {
                        --v.number;
                    }
                    if (v.number > 0) {
                        return v;
                    }
                });
                // this.shoppingCarList = shoppingCarList;
                sessionStorage.setItem('shoppingCar', JSON.stringify(this.shoppingCarList));
                sessionStorage.setItem('shoppingCarNum', this.shoppingCarList.length);
                this.upDataSupplierList();
                this.upDateData();
            },
            increase(item){//增加
                let totalNum = 0;

                // !this.isEdit ?
                this.shoppingCarList.forEach(v=>{
                    v.check && (totalNum = totalNum + v.number);
                });
                console.log(item.check);
                if (totalNum >= this.maxNum && item.check) {
                    alert(`一次最多购买${this.maxNum}件商品`);
                    return;
                }
                this.shoppingCarList.map((v,i)=>{
                    if (v.skuCode === item.skuCode && v.skuPrice === item.skuPrice) {
                        ++v.number;
                    }
                });
                this.upDateData();
            },
            //勾选商品
            checkboxChange(check, index){
                //判断是否勾选了最大数量
                let totalNum = 0;
                // let arr = JSON.parse(JSON.stringify(this.shoppingCarList));
                // let arr = [...this.shoppingCarList];
                this.shoppingCarList.forEach(v=>{
                    v.check && (totalNum = totalNum + v.number);
                });
                if (totalNum > this.maxNum) {
                    this.shoppingCarList[index].check = false;
                    //这里要把shoppingCarList改为空再改回来，否则页面不刷新数据
                    // this.shoppingCarList = [];
                    // this.shoppingCarList = arr;
                    alert(`一次最多购买${this.maxNum}件商品`);
                }
                //是否全选
                this.shoppingCarList.find(v=>{
                    this.allChoice_ = true;
                    if (!v.check) {
                        this.allChoice_ = false;
                        return true
                    }
                });
                //同步全局变量购物车信息
                // this.$parent.globalData.shoppingCar = this.shoppingCarList;
                sessionStorage.setItem('shoppingCar', JSON.stringify(this.shoppingCarList));
                this.upDateData();
            },
            //全选商品
            allChoice(check){
                if (check) {
                    //判断是否勾选了最大数量
                    let totalNum = 0;
                    this.shoppingCarList.forEach(v=>{
                        totalNum = totalNum + v.number;
                    });
                    if (totalNum > this.maxNum) {
                        alert(`一次最多购买${this.maxNum}件商品`);
                        this.allChoice_ = true;
                        this.allChoice_ = false;
                        return false;
                    }

                    //将所有商品打勾
                    this.shoppingCarList.map(v=>{
                        v.check = true;
                    });
                    this.allChoice_ = true;
                }
                else{
                    this.shoppingCarList.map(v=>{
                        v.check = false;
                    });
                    this.allChoice_ = false;
                }
                sessionStorage.setItem('shoppingCar', JSON.stringify(this.shoppingCarList));
                this.upDateData();
            },
            //创建订单
            createOrder(){
                let skuListStr = '';
                let testFlag1 = 0;//测试商品
                let testFlag0 = 0;//非测试商品

                this.shoppingCarList.forEach(v=>{
                    if (v.check) {
                        skuListStr += `${v.skuCode},${v.number},${v.skuPrice},${v.region};`
                    }
                    if (v.testFlag === 1) testFlag1++;
                    if (v.testFlag === 0) testFlag0++;
                    if (!v.testFlag) testFlag0++;//没有testFlag按非测试商品处理
                });

                if (skuListStr === '') {
                    alert('请至少勾选一件商品');
                    return false;
                }
                if (testFlag1 !== 0 && testFlag0 !== 0) { //既有测试商品又有非测试商品
                    alert('订单中包含测试和非测试商品');
                    return false;
                }

                request({
                    url: "/web/order/createOrder",
                    // url: "aquaSku/getSkuList",
                    method: "POST",
                    data: {
                        // vin:'LHT5Y2A46MC32A001',//355
                        vin: 'LHT5Y2A4XLC4WA003',//303
                        orderType:19,//震雷说让写死19
                        skuListStr,
                    },
                }).then((res) => {
                    // console.log(res)
                    console.log(res.result.orderCode)
                    //有商品库存不足
                    if (res.notEnouthSku) {
                        res.notEnouthSku.forEach(v=>{
                            this.shoppingCarList.map((val,i)=>{
                                if (v.skuCode === val.skuCode && v.skuPrice === val.skuPrice && v.region === val.region) {
                                    val.skuCount = v.skuCount;
                                    // val.number = v.skuCount;
                                    val.check = false;
                                    this.allChoice_ = false;
                                }
                            });
                            alert('有商品库存不足');
                        });
                        console.log(this.shoppingCarList);
                        this.upDateData();
                        return;
                    }
                    let order = res.result;
                    this.orderCode = res.result.orderCode || '';
                    this.bonus = order.bonus;

                    if (this.userInfo.grayRelease) {//灰度测试
                        sessionStorage.setItem('shoppingCar', JSON.stringify([]));
                        sessionStorage.setItem('shoppingCarNum', 0);
                        this.$router.push({
                          path: 'PreOrder',
                          query: {
                            orderCode: res.result.orderCode
                          }
                        })
                    }
                    else{
                        if (this.totalPrice === 0) {
                            this.shoppingCarList = [];
                            sessionStorage.setItem('shoppingCar', JSON.stringify([]));
                            sessionStorage.setItem('shoppingCarNum', 0);
                            // wepy.redirectTo({
                            //     url: `result?state=${1}&orderID=${this.orderCode}`
                            // });
                            return;
                        }
                        this.pay(order);
                    }
                })


            },
            pay(order){
                console.log(order)
            },
            confirm2(){
                let _this = this;

                _this.showClearModal = false;
                _this.shoppingCarList = [];
                sessionStorage.setItem('shoppingCar', JSON.stringify(_this.shoppingCarList));
                _this.upDateData();
                _this.upDataSupplierList();
            },
            cancel2(){
                this.showClearModal = false;
            },
            //更新商家列表
            upDataSupplierList(){
                let supplierList = [];
                let supplierList2 = [];
                this.shoppingCarList.map(v=>{
                    if (!supplierList.includes(v.supplierName)) {
                        supplierList.push(v.supplierName);
                        supplierList2.push(v.supplierUrl);
                    }
                });
                console.log(supplierList, 'supplierList');
                this.supplierList = supplierList;
                this.supplierList2 = supplierList2;
            },
            //计算价格
            getTotalPrice(arg1, arg2 = 100) {
                let m = 0;
                let s1 = arg1.toString();
                let s2 = arg2.toString();

                try {
                    m += s1.split(".")[1].length
                }
                catch (e) {}

                try {
                    m += s2.split(".")[1].length
                }
                catch (e) {}

                return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)
            },
        }
    }
</script>

